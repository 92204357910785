<template>
  <div class="RealName">
    <a ref="download" style="display: none" />
    <h2>人员管理</h2>
    <el-button type="primary" @click="handleAdd" :disabled="onCheckBtnAuthority('RealNameAdd')">添加</el-button>
    <el-button @click="handleIssue" :disabled="onCheckBtnAuthority('RealNameIssue')">批量下发</el-button>
    <el-button type="danger" plain @click="handleExit" :disabled="onCheckBtnAuthority('RealNameExit')">批量退场</el-button>
    <el-button type="success" plain @click="handleRealNameExport" :disabled="onCheckBtnAuthority('RealNameExport')">批量导出</el-button>
    <el-upload action="" 
      :multiple="false"
      :auto-upload="false"
      :show-file-list="false"
      :on-change='handleRealNameImport'
      accept=".xls, .xlsx"
      style="display: inline-block; margin: 0 10px;">
      <el-button type="warning" plain :disabled="onCheckBtnAuthority('RealNameImport')">人员导入</el-button>
    </el-upload>
    <el-upload action="" 
      :multiple="false"
      :auto-upload="false"
      :show-file-list="false"
      :on-change='handleRealNameImportPic'
      accept=".zip, "
      style="display: inline-block; margin-right: 10px;">
      <el-button type="warning" plain :disabled="onCheckBtnAuthority('RealNameImportPic')">打卡机照片导入</el-button>
    </el-upload>
    <el-upload action="" 
      :multiple="false"
      :auto-upload="false"
      :show-file-list="false"
      :on-change='handleRealNameImportIcon'
      accept=".zip, "
      style="display: inline-block; margin-right: 10px;">
      <el-button type="warning" plain :disabled="onCheckBtnAuthority('RealNameImportIcon')">人员头像导入</el-button>
    </el-upload>
    <el-button @click="handleRealNameTemplate" :disabled="onCheckBtnAuthority('RealNameTemplate')">模板下载</el-button>
    <div class="searchWrap">
      <el-row>
        <el-col :span="4">
          <div class="sb">
            <span>人员姓名</span>
            <el-input
              class="searchInput"
              size="small"
              placeholder="请输入内容"
              v-model="workerName"
              clearable
            >
            </el-input>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="sb">
            <span>参建单位</span>
            <el-select
              v-model="corpId"
              clearable
              class="searchInput"
              size="small"
              placeholder="请选择"
            >
              <el-option
                v-for="item in corpList"
                :key="item.corpId"
                :label="item.corpNmae"
                :value="item.corpId"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="sb">
            <span>部门班组</span>
            <el-select
              v-model="teamId"
              clearable
              class="searchInput"
              size="small"
              placeholder="请选择"
            >
              <el-option
                v-for="item in teamList"
                :key="item.teamId"
                :label="item.teamName"
                :value="item.teamId"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="sb">
            <span>人员状态</span>
            <el-select
              v-model="personnelStatus"
              clearable
              class="searchInput"
              size="small"
              placeholder="请选择"
            >
              <el-option label="未下发" value="0"></el-option>
              <el-option label="已下发" value="1"></el-option>
              <el-option label="第三方" value="2"></el-option>
              <el-option label="无照片" value="3"></el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="sb">
            <span>在场状态</span>
            <el-select
              v-model="isComplete"
              clearable
              class="searchInput"
              size="small"
              placeholder="请选择"
            >
              <el-option label="进场" value="0"></el-option>
              <el-option label="退场" value="1"></el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" size="small" @click="onGetListData"
            >搜索</el-button
          >
          <el-button type="primary" size="small" @click="handleRealNameExportResult" :disabled="onCheckBtnAuthority('RealNameExportResult')"
            >导出当前数据</el-button
          >
        </el-col>
      </el-row>
    </div>
    <el-table :data="listData" @selection-change="handleSelectionChange" style="margin-top: 20px">
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column type="index" width="50"> </el-table-column>
      <el-table-column prop="workerName" label="工人姓名"> </el-table-column>
      <el-table-column prop="personnelStatus" label="人员状态">
        <template slot-scope="scope">
          <span style="color: #F56C6C" v-if="scope.row.personnelStatus == '0'">未下发</span>
          <span style="color: #67C23A" v-if="scope.row.personnelStatus == '1'">已下发</span>
          <span v-if="scope.row.personnelStatus == '2'">第三方</span>
          <span style="color: #E6A23C" v-if="scope.row.personnelStatus == '3'">无照片</span>
        </template>
      </el-table-column>
      <el-table-column prop="idcardNumber" label="身份证号"> </el-table-column>
      <el-table-column prop="corpName" label="参建单位"> </el-table-column>
      <el-table-column prop="teamName" label="班组"> </el-table-column>
      <el-table-column prop="entryTime" label="进场时间"> </el-table-column>
      <el-table-column prop="exitTime" label="退场时间"> </el-table-column>
      <el-table-column prop="isComplete" label="在场状态">
        <template slot-scope="scope">
          <span v-if="scope.row.isComplete == '0'">进场</span>
          <span v-if="scope.row.isComplete == '1'">退场</span>
        </template>
      </el-table-column>
      <el-table-column align="right" width="240">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleDataForm(scope.row, 'detail')" :disabled="onCheckBtnAuthority('RealNameDetail')"
            >详情</el-button
          > 
          <el-button size="mini" @click="handleDataForm(scope.row, 'edit')" :disabled="onCheckBtnAuthority('RealNameEdit')"
          >修改</el-button
          >
          <el-button size="mini" @click="handleLogList(scope.row)" :disabled="onCheckBtnAuthority('RealNameIssueResult')"
            >下发结果</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="total, sizes, prev, pager, next"
      :total="totals"
      :page-size="pageSize"
      :current-page.sync="pageNum"
      @current-change="onGetListData"
      @size-change="handleSizeChange"
      :page-sizes="[5, 10, 20, 30, 50]"
    >
    </el-pagination>

    <el-dialog
      title="实名信息"
      :visible.sync="showDataForm"
      width="1300px"
      :before-close="handleClose"
    >
      <div>
        <div class="container">
          <div class="left">
            <div class="flex" style="align-items: flex-start">
              <div class="idInfo">
                <div class="flex">
                  <div class="lab" style="width: 60px"><span class="required">*</span>姓名：</div>
                  <el-input
                    v-model="dataForm.workerName"
                    clearable
                    size="mini"
                    style="width: 290px"
                    placeholder="请输入"
                    :disabled="dataFormType != 'add'"
                  ></el-input>
                </div>
                <div class="flex">
                  <div class="lab" style="width: 60px"><span class="required">*</span>性别：</div>
                  <el-select
                    v-model="dataForm.gender"
                    clearable
                    size="mini"
                    style="width: 100px; margin-right: 20px"
                    placeholder="请选择"
                    :disabled="dataFormType != 'add'"
                  >
                    <el-option label="男" value="0"></el-option>
                    <el-option label="女" value="1"></el-option>
                  </el-select>
                  <div class="lab" style="width: 60px"><span class="required">*</span>民族：</div>
                  <el-input
                    v-model="dataForm.nation"
                    clearable
                    size="mini"
                    style="width: 90px"
                    placeholder="请输入"
                    :disabled="dataFormType != 'add'"
                  ></el-input>
                </div>
                <div class="flex">
                  <div class="lab" style="width: 60px"><span class="required">*</span>出生：</div>
                  <el-date-picker
                    size="mini"
                    clearable
                    style="width: 290px;"
                    v-model="dataForm.birthday"
                    value-format="yyyy-MM-dd hh:ss:mm"
                    type="date"
                    placeholder="选择日期"
                    :disabled="dataFormType != 'add'"
                  >
                  </el-date-picker>
                </div>
                <div class="flex">
                  <div class="lab" style="width: 60px"><span class="required">*</span>住址：</div>
                  <el-input
                    v-model="dataForm.address"
                    clearable
                    size="mini"
                    style="width: 290px"
                    placeholder="请输入"
                    :disabled="dataFormType == 'detail'"
                  ></el-input>
                </div>
                <div class="flex">
                  <div class="lab" style="width: 130px"><span class="required">*</span>公民身份证号码：</div>
                  <el-input
                    v-model="dataForm.idcardNumber"
                    clearable
                    size="mini"
                    style="width: 220px"
                    placeholder="请输入"
                    :disabled="dataFormType != 'add'"
                  ></el-input>
                </div>
                <div class="flex">
                  <div class="lab" style="width: 90px"><span class="required">*</span>签发机关：</div>
                  <el-input
                    v-model="dataForm.grantOrg"
                    clearable
                    size="mini"
                    style="width: 260px"
                    placeholder="请输入"
                    :disabled="dataFormType == 'detail'"
                  ></el-input>
                </div>
                <div class="flex" style="margin-bottom: 0">
                  <div class="lab" style="width:90px"><span class="required">*</span>有效日期：</div>
                  <el-date-picker
                    size="mini"
                    style="width: 120px; margin-right: 20px"
                    v-model="dataForm.startDate"
                    value-format="yyyy-MM-dd hh:ss:mm"
                    type="date"
                    placeholder="选择日期"
                    :disabled="dataFormType != 'add'"
                  >
                  </el-date-picker>
                  <el-date-picker
                    size="mini"
                    style="width: 120px"
                    v-model="dataForm.expiryDate"
                    value-format="yyyy-MM-dd hh:ss:mm"
                    type="date"
                    placeholder="选择日期"
                    :disabled="dataFormType != 'add'"
                  >
                  </el-date-picker>
                </div>
              </div>
              <div class="avatarWrap">
                <el-image
                  style="width: 102px; height: 126px"
                  :src="dataForm.avatar"
                  fit="contain"
                >
                  <div
                    slot="error"
                    class="avatar"
                    style="
                      background-color: #eee;
                      border: 1px dashed #8a8a8a;
                      border-radius: 10px;
                      width: 100px;
                      height: 124px;
                      text-align: center;
                      line-height: 206px;
                    "
                  >
                    头像
                  </div>
                </el-image>
              </div>
            </div>
          </div>
          <div class="right">
            <el-image
              style="width: 327px; height: 206px; margin: 20px 0 0 20px"
              :src="dataForm.frontView"
              fit="contain"
            >
              <div
                slot="error"
                class="idCard"
                style="
                  background-color: #eee;
                  border: 1px dashed #8a8a8a;
                  border-radius: 10px;
                  width: 325px;
                  height: 204px;
                  text-align: center;
                  line-height: 206px;
                "
              >
                身份证正面照片
              </div>
            </el-image>
            <el-image
              style="width: 327px; height: 206px; margin: 20px 0 0 20px"
              :src="dataForm.reverseView"
              fit="contain"
            >
              <div
                slot="error"
                class="idCard"
                style="
                  background-color: #eee;
                  border: 1px dashed #8a8a8a;
                  border-radius: 10px;
                  width: 325px;
                  height: 204px;
                  text-align: center;
                  line-height: 206px;
                "
              >
                身份证背面照片
              </div>
            </el-image>
          </div>
        </div>
        <div class="formDataWrap">
          <el-form
            label-width="130px"
            label-position="left"
            style="flex-grow: 1"
            :rules="formRules"
          >
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="参建单位" prop="corpId">
                  <el-select
                    v-model="dataForm.corpId"
                    clearable
                    class="searchInput"
                    size="mini"
                    placeholder="请选择"
                    style="width: 100%"
                    @change="handleCorpIdChange"
                    :disabled="dataFormType != 'add'"
                  >
                    <el-option
                      v-for="item in corpList"
                      :key="item.corpId"
                      :label="item.corpNmae"
                      :value="item.corpId"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="班组" prop="teamId">
                  <el-select
                    v-model="dataForm.teamId"
                    clearable
                    class="searchInput"
                    size="mini"
                    placeholder="请选择"
                    style="width: 100%"
                    :disabled="dataFormType != 'add'"
                  >
                    <el-option
                      v-for="item in teamList"
                      :key="item.teamId"
                      :label="item.teamName"
                      :value="item.teamId"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="人员类型" prop="workerType">
                  <el-select
                    v-model="dataForm.workerType"
                    clearable
                    class="searchInput"
                    size="mini"
                    placeholder="请选择"
                    style="width: 100%"
                    :disabled="dataFormType != 'add'"
                  >
                    <el-option
                      v-for="item in workerTypeList"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="工种/岗位" prop="workCurrentType">
                  <el-select
                    v-model="dataForm.workCurrentType"
                    clearable
                    class="searchInput"
                    size="mini"
                    placeholder="请选择"
                    style="width: 100%"
                    :disabled="dataFormType != 'add'"
                  >
                    <el-option
                      v-for="item in workCurrentTypeList"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="手机号" prop="cellPhone">
                  <el-input
                    v-model="dataForm.cellPhone"
                    clearable
                    size="mini"
                    placeholder="请输入"
                    :disabled="dataFormType == 'detail'"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="进场时间">
                  <el-date-picker
                    size="mini"
                    style="width: 100%"
                    v-model="dataForm.entryTime"
                    value-format="yyyy-MM-dd hh:ss:mm"
                    type="date"
                    placeholder="选择日期"
                    :disabled="dataFormType == 'detail'"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="文化程度">
                  <el-select
                    v-model="dataForm.cultureLevelType"
                    clearable
                    class="searchInput"
                    size="mini"
                    placeholder="请选择"
                    style="width: 100%"
                    :disabled="dataFormType == 'detail'"
                  >
                    <el-option
                      v-for="item in cultureLevelTypeList"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="是否有重大疾病史">
                  <el-select
                    v-model="dataForm.hasBadMedicalHistory"
                    clearable
                    class="searchInput"
                    size="mini"
                    placeholder="请选择"
                    style="width: 100%"
                    :disabled="dataFormType == 'detail'"
                  >
                    <el-option
                      v-for="item in hasBadMedicalHistoryList"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="政治面貌">
                  <el-select
                    v-model="dataForm.politicsType"
                    clearable
                    class="searchInput"
                    size="mini"
                    placeholder="请选择"
                    style="width: 100%"
                    :disabled="dataFormType == 'detail'"
                  >
                    <el-option
                      v-for="item in politicsTypeList"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="加入工会时间">
                  <el-date-picker
                    size="mini"
                    style="width: 100%"
                    v-model="dataForm.joinedTime"
                    value-format="yyyy-MM-dd hh:ss:mm"
                    type="date"
                    placeholder="选择日期"
                    :disabled="dataFormType == 'detail'"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="紧急联系人">
                  <el-input
                    v-model="dataForm.urgentLinkMan"
                    clearable
                    size="mini"
                    placeholder="请输入"
                    :disabled="dataFormType == 'detail'"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="紧急联系方式">
                  <el-input
                    v-model="dataForm.urgentLinkManPhone"
                    clearable
                    size="mini"
                    placeholder="请输入"
                    :disabled="dataFormType == 'detail'"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="婚姻状况">
                  <el-select
                    v-model="dataForm.maritalStatus"
                    clearable
                    class="searchInput"
                    size="mini"
                    placeholder="请选择"
                    style="width: 100%"
                    :disabled="dataFormType == 'detail'"
                  >
                    <el-option
                      v-for="item in maritalStatusList"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="血型">
                  <el-select
                    v-model="dataForm.blood"
                    clearable
                    class="searchInput"
                    size="mini"
                    placeholder="请选择"
                    :disabled="dataFormType == 'detail'"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in bloodList"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div class="setPhoto">
            <el-image
              v-show="!videoShow"
              :src="dataForm.clockIn"
              style="width: 205px; height: 205px; border-radius: 10px"
              fit="contain"
            >
              <div
                slot="error"
                style="
                  background-color: #eee;
                  border: 1px dashed #8a8a8a;
                  border-radius: 10px;
                  width: 203px;
                  height: 203px;
                  text-align: center;
                  line-height: 205px;
                "
              >
                打卡机照片
              </div>
            </el-image>
            <video
              v-show="videoShow"
              ref="video"
              autoplay
              style="
                width: 205px;
                height: 205px;
                border-radius: 10px;
                background-color: #000;
              "
            ></video>
            <canvas
              style="display: none; background-color: #f00"
              ref="canvas"
              width="205px"
              height="205px"
            ></canvas>
          </div>
        </div>

        <div class="buttons" v-if="dataFormType != 'detail'">
          <el-upload action="" 
            :multiple="false"
            :auto-upload="false"
            :show-file-list="false"
            :on-change='handleUploadAvatar'
            accept=".jpg, jpeg"
            style="display: inline-block; margin-right: 10px;">
            <el-button :disabled="dataFormType != 'add'">上传头像</el-button>
          </el-upload>
          <el-upload action="" 
            :multiple="false"
            :auto-upload="false"
            :show-file-list="false"
            :on-change='handleUploadFrontView'
            accept=".jpg, jpeg"
            style="display: inline-block; margin-right: 10px;">
            <el-button :disabled="dataFormType != 'add'">上传身份证正面照</el-button>
          </el-upload>
          <el-upload action="" 
            :multiple="false"
            :auto-upload="false"
            :show-file-list="false"
            :on-change='handleUploadReverseView'
            accept=".jpg, jpeg"
            style="display: inline-block; margin-right: 10px;">
            <el-button :disabled="dataFormType != 'add'">上传身份证背面照</el-button>
          </el-upload>
          <el-upload action="" 
            :multiple="false"
            :auto-upload="false"
            :show-file-list="false"
            :on-change='handleUploadClockIn'
            accept=".jpg, jpeg"
            style="display: inline-block; margin-right: 10px;">
            <el-button>上传打卡机照片</el-button>
          </el-upload>
          <el-button type="success" @click="camraOpen" plain
            >打开摄像头</el-button
          >
          <el-button type="danger" @click="camraClose" plain
            >关闭摄像头</el-button
          >
          <el-button type="warning" @click="camraCapture" plain
            >拍摄打卡机照片</el-button
          >
          <el-button
            type="warning"
            @click="getIdCardData"
            :disabled="dataFormType != 'add'"
            >读取二代身份证</el-button
          >
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button
          type="primary"
          v-if="dataFormType != 'detail'"
          @click="handleSubmit"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="下发结果"
      :visible.sync="showLogList"
      width="40%"
      :before-close="handleCloseLogList"
    >
      <el-table :data="logList" style="margin-top: 20px">
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column prop="deviceCode" label="设备编码"> </el-table-column>
        <el-table-column prop="deviceName" label="设备名称"> </el-table-column>
        <el-table-column prop="status" label="处理结果">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0">成功</span>
            <span v-if="scope.row.status == 1">失败</span>
            <span v-if="scope.row.status == 2">处理中</span>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleCloseLogList">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      width="660px"
      title="图片剪裁"
      :visible.sync="innerVisible"
      append-to-body>
      <div class="mainWrap">
        <div class="cropperWrap">
          <vueCropper
            ref="cropper"
            :img="option.img"
            :outputSize="option.size"
            :outputType="option.outputType"
            :autoCrop="true"
            :autoCropWidth="cropperWidth"
            :autoCropHeight="cropperHeight"
            :fixed="true"
            :fixedNumber="[cropperWidth, cropperHeight]"
            :centerBox="true"
            @realTime="realTime"
          ></vueCropper>
        </div>
        <div class="previewWrap">
          <span class="realTime">实时预览:</span>
          <div :style="previewStyle"> 
            <div :style="previews.div">
              <img :src="previews.url" :style="previews.img">
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button icon="el-icon-plus" @click="changeScale(1)"></el-button>
        <el-button icon="el-icon-minus" @click="changeScale(-1)"></el-button>
        <el-button icon="el-icon-refresh-left" @click="rotateLeft"></el-button>
        <el-button icon="el-icon-refresh-right" @click="rotateRight"></el-button>
        <el-button icon="el-icon-check" type="primary" @click="getCropData"></el-button>
      </span>
    </el-dialog>
  </div>
</template>
    
<style lang="less" scoped>
.RealName {
  .required {
    margin-right: 3px;
    color: #F56C6C;
  }
  padding: 20px;
  .container {
    display: flex;
    justify-content: space-between;
    .right {
      width: 714px;
      border: 1px solid #eee;
      height: 280px;
      box-sizing: border-box;
    }
    .left {
      flex-grow: 1;
      border: 1px solid #eee;
      margin-right: 20px;
      padding: 20px;
      .flex {
        display: flex;
        align-items: center;
        margin-bottom: 6px;
        .lab {
          margin-right: 20px;
        }
      }
      .idInfo {
        flex-grow: 1;
        margin-right: 20px;
      }
      .avatar {
        width: 102px;
        height: 106px;
      }
    }
    .idCard {
      border-radius: 10px;
    }
  }
  .formDataWrap {
    margin-top: 20px;
    border: 1px solid #eee;
    padding: 20px;
    display: flex;
    .el-form-item {
      margin-bottom: 0;
    }
  }
  .setPhoto {
    width: 205;
    height: 205;
    margin-left: 20px;
  }
  .buttons {
    margin-top: 20px;
    text-align: center;
  }
}
.mainWrap {
  background-color: #eee;
  width: 620px;
  display: flex;
}
.cropperWrap {
  width: 400px;
  height: 300px;
  background-color: #eee;
}
.previewWrap {
  margin: 0 auto;
  width: 200px;
  min-height: 1px;
}
.realTime {
  display: block;
  margin: 6px 0;
}
</style>
    
<script>
export default {
  name: "RealName",
  components: {},
  data() {
    return {
      pageBtn: [],
      pageSize: 10,
      pageNum: 1,
      totals: 0,
      listData: [],
      workerName: "",
      corpId: "",
      teamId: "",
      personnelStatus: "",
      isComplete: "",
      corpList: [],
      teamList: [],
      videoShow: false,
      showDataForm: false,
      dataFormType: null,
      currentWorkerId: null,
      entryExitId: null,
      dataForm: {
        projectId: localStorage.getItem("projectId"),
        idcardType: "01",

        workerName: "",
        gender: "",
        nation: "",
        birthday: "",
        address: "",
        idcardNumber: "",
        grantOrg: "",
        startDate: "",
        expiryDate: "",
        avatar: "",
        frontView: "",
        reverseView: "",

        corpId: "",
        teamId: "",
        workerType: "",
        workCurrentType: "",
        cellPhone: "",
        entryTime: "",

        cultureLevelType: "",
        hasBadMedicalHistory: "",
        politicsType: "",
        joinedTime: "",
        urgentLinkMan: "",
        urgentLinkManPhone: "",
        maritalStatus: "",
        blood: "",
        clockIn: "",

        workCorpName: "",
      },
      formRules: {
        corpId: [{ required: true, message: "必填项", trigger: "blur" }],
        teamId: [{ required: true, message: "必填项", trigger: "blur" }],
        workerType: [{ required: true, message: "必填项", trigger: "blur" }],
        workCurrentType: [{ required: true, message: "必填项", trigger: "blur" }],
        cellPhone: [{ required: true, message: "必填项", trigger: "blur" }],
      },
      // 字典
      workerTypeList: [],
      workCurrentTypeList: [],
      cultureLevelTypeList: [],
      hasBadMedicalHistoryList: [],
      politicsTypeList: [],
      maritalStatusList: [],
      bloodList: [],

      multipleSelection: [],
      logList: [],
      showLogList: false,


      innerVisible: false,
      base64Data: null,
      cropperWidth: 327,
      cropperHeight: 206,
      cropperType: "",

      option: {
        img: '',
        size: 1,
        outputType: 'jpeg'
      },
      previews: {
        div: null,
        html: null,
        img: null,
        url: null,
        w: null,
        h: null
      },
      previewStyle: {

      }
    };
  },
  computed: {
    workIdList: function () {
      var list = []
      this.multipleSelection.forEach(item => {
        list.push(item.workerId)
      })
      return list
    },
  },
  watch: {
    corpId: function () {
      this.pageNum = 1
      this.teamId = ""
      this.teamList = []
      this.onGetListData();
      this.onGetTeamList();
    },
    teamId: function () {
      this.pageNum = 1
      this.onGetListData();
    },
    isComplete: function () {
      this.pageNum = 1
      this.onGetListData()
    },
    personnelStatus: function () {
      this.pageNum = 1
      this.onGetListData()
    },
  },
  mounted() {
    this.onGetPageBtn();
    this.onGetListData();
    this.onGetCorpList();
    this.onGetWorkerTypeList();
    this.onGetWorkCurrentTypeList();
    this.onGetCultureLevelTypeList();
    this.onGetHasBadMedicalHistoryList();
    this.onGetPoliticsTypeList();
    this.onGetMaritalStatusList();
    this.onGetBloodList();
  },
  methods: {
    goto: function (path) {
      this.$router.push({
        name: path,
      });
    },
    // 获取当前页面按钮权限
    onGetPageBtn: function () {
      var that = this;
      var data = {
        id: "1688759091804565505",
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/menu/buttons",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.pageBtn = res.data;
          } else {
            that.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 检查按钮权限
    onCheckBtnAuthority: function (code) {
      var disable = true;
      if (this.pageBtn.length) {
        this.pageBtn[0].children.forEach((item) => {
          if (item.code == code) {
            disable = false;
          }
        });
      }
      return disable;
    },
    // 获取主列表数据
    onGetListData: function () {
      var that = this;
      var data = {
        status: 0,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        projectId: localStorage.getItem("projectId"),
        workerName: this.workerName,
        corpId: this.corpId,
        teamId: this.teamId,
        isComplete: this.isComplete,
        personnelStatus: this.personnelStatus,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/projectWorker/list",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.listData = res.data.dataList;
            that.totals = res.data.totals;
          } else {
            that.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 切换分页大小
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.onGetListData();
    },
    // 获取参建单位列表
    onGetCorpList: function () {
      var that = this;
      var data = {
        projectId: localStorage.getItem("projectId"),
        pageSize: 10000,
        pageNum: 1,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/realProjectSubContractor/list",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.corpList = res.data.dataList;
          } else {
            that.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取部门班组列表
    onGetTeamList: function () {
      var that = this;
      if (!this.corpId || this.corpId == "") { return }
      var data = {
        projectId: localStorage.getItem("projectId"),
        corpId: this.corpId,
        pageSize: 10,
        pageNum: 1,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/realProjectTeam/list",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.teamList = res.data.dataList;
          } else {
            that.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 读取二代身份证信息
    getIdCardData: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that
        .$axios({
          method: "get",
          url: "http://localhost:8989/api/ReadMsg?cardImg=1&waitTime=3",
          // url: "http://192.168.124.29:8989/api/ReadMsg?cardImg=1&waitTime=3",
        })
        .then((res) => {
          if (res.data.code == "0") {
            that.$message({
              title: "操作成功",
              message: res.data.retmsg,
              type: "success",
            });
            // 写入数据
            that.dataForm.workerName = res.data.name;
            that.dataForm.gender = that.formatGender(res.data.sex);
            that.dataForm.nation = res.data.nation;
            that.dataForm.birthday = that.getFullDateStr(that.formatDate(res.data.born));
            that.dataForm.address = res.data.address;
            that.dataForm.idcardNumber = res.data.cardno;
            that.dataForm.grantOrg = res.data.police;
            that.dataForm.startDate = that.getFullDateStr(that.formatDate(res.data.userlifeb));
            that.dataForm.expiryDate = that.getFullDateStr(that.formatDate(res.data.userlifee));
            that.dataForm.avatar =
              "data:image/jpg;base64," + res.data.photobase64;
            that.dataForm.frontView =
              "data:image/jpg;base64," + res.data.frontImg;
            that.dataForm.reverseView =
              "data:image/jpg;base64," + res.data.backImg;
          } else {
            that.$message({
              title: "操作失败",
              message: res.data.retmsg,
              type: "warning",
            });
          }
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.$message({
            title: "操作失败",
            message: "设备连接失败，请检查设备或网络。",
            type: "warning",
          });
          that.loading.close();
        });
    },
    // 格式化身份证日期
    formatDate: function (str) {
      return str.replace(/^(\d{4})(\d{2})(\d{2})$/, "$1-$2-$3");
    },
    // 格式化性别
    formatGender: function (str) {
      if (str == "男") {
        return "0";
      }
      if (str == "女") {
        return "1";
      }
    },
    // 参建单位变更
    handleCorpIdChange: function (env) {
      console.log("handleCorpIdChange", env);
      this.corpId = env;
      this.dataForm.teamId = "";
      this.corpList.forEach((item) => {
        if (item.corpId == env) {
          this.dataForm.workCorpName = item.corpNmae;
        }
      });
    },
    // 关闭
    handleClose: function () {
      this.dataForm.workerName = "";
      this.dataForm.gender = "";
      this.dataForm.nation = "";
      this.dataForm.birthday = "";
      this.dataForm.address = "";
      this.dataForm.idcardNumber = "";
      this.dataForm.grantOrg = "";
      this.dataForm.startDate = "";
      this.dataForm.expiryDate = "";
      this.dataForm.avatar = "";
      this.dataForm.frontView = "";
      this.dataForm.reverseView = "";

      this.dataForm.corpId = "";
      this.dataForm.teamId = "";
      this.dataForm.workerType = "";
      this.dataForm.workCurrentType = "";
      this.dataForm.cellPhone = "";
      this.dataForm.entryTime = "";

      this.dataForm.cultureLevelType = "";
      this.dataForm.hasBadMedicalHistory = "";
      this.dataForm.politicsType = "";
      this.dataForm.joinedTime = "";
      this.dataForm.urgentLinkMan = "";
      this.dataForm.urgentLinkManPhone = "";
      this.dataForm.maritalStatus = "";
      this.dataForm.blood = "";
      this.dataForm.clockIn = "";

      this.dataForm.workCorpName = "";

      this.showDataForm = false;
      this.dataFormType = null;
      this.currentWorkerId = null;
      this.entryExitId = null;

      this.camraClose()
    },
    // 添加
    handleAdd: function () {
      this.handleClose();
      this.dataFormType = "add";
      this.showDataForm = true;
    },
    // 获取人员类型字典
    onGetWorkerTypeList: function () {
      var that = this;
      var data = {
        type: "personnelType",
        className: "General",
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/dictionary/common-interface",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.workerTypeList = res.data;
          } else {
            that.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取工种字典
    onGetWorkCurrentTypeList: function () {
      var that = this;
      var data = {
        type: "typeOfWork",
        className: "General",
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/dictionary/common-interface",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.workCurrentTypeList = res.data;
          } else {
            that.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取文化程度字典
    onGetCultureLevelTypeList: function () {
      var that = this;
      var data = {
        type: "educationalLevel",
        className: "General",
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/dictionary/common-interface",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.cultureLevelTypeList = res.data;
          } else {
            that.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取是否有重大疾病字典
    onGetHasBadMedicalHistoryList: function () {
      var that = this;
      var data = {
        type: "whether",
        className: "General",
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/dictionary/common-interface",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.hasBadMedicalHistoryList = res.data;
          } else {
            that.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取政治面貌字典
    onGetPoliticsTypeList: function () {
      var that = this;
      var data = {
        type: "politicalStatus",
        className: "General",
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/dictionary/common-interface",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.politicsTypeList = res.data;
          } else {
            that.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取婚姻状况字典
    onGetMaritalStatusList: function () {
      var that = this;
      var data = {
        type: "maritalStatus",
        className: "General",
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/dictionary/common-interface",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.maritalStatusList = res.data;
          } else {
            that.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取血型字典
    onGetBloodList: function () {
      var that = this;
      var data = {
        type: "blood",
        className: "General",
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/dictionary/common-interface",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.bloodList = res.data;
          } else {
            that.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 打开摄像头
    camraOpen: function () {
      this.videoShow = true;
      this.dataForm.clockIn = "";
      var video = this.$refs.video;
      //访问摄像头
      if (
        navigator.mediaDevices.getUserMedia ||
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia
      ) {
        //调用用户媒体设备, 访问摄像头
        getUserMedia({ video: { width: 205, height: 205 } }, success, error);
      } else {
        alert("不支持访问用户媒体");
      }

      function getUserMedia(constraints, success, error) {
        if (navigator.mediaDevices.getUserMedia) {
          navigator.mediaDevices
            .getUserMedia(constraints)
            .then(success)
            .catch(error);
        } else if (navigator.webkitGetUserMedia) {
          navigator.webkitGetUserMedia(constraints, success, error);
        } else if (navigator.mozGetUserMedia) {
          navigator.mozGetUserMedia(constraints, success, error);
        } else if (navigator.getUserMedia) {
          navigator.getUserMedia(constraints, success, error);
        }
      }
      //成功回调
      function success(stream) {
        var CompatibleURL = window.URL || window.webkitURL;
        video.srcObject = stream;
        video.play();
      }
      //失败回调
      function error(error) {
        console.log("访问用户媒体设备失败", error);
      }
    },
    // 关闭摄像头
    camraClose: function () {
      this.videoShow = false;
      var video = this.$refs.video;
      if(video && video.srcObject) {
        video.srcObject.getTracks()[0].stop();
      }
    },
    // 捕捉画面
    camraCapture: function () {
      this.videoShow = false;
      var video = this.$refs.video;
      var canvas = this.$refs.canvas;
      var context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, 205, 205, 0, 0, 205, 205);
      var imageData = canvas.toDataURL("image/jpeg", 1.0);
      console.log("imageData =>", imageData);
      this.dataForm.clockIn = imageData;
      this.camraClose();
    },
    // 控制实名信息窗口
    handleDataForm: function (row, type) {
      this.currentWorkerId = row.workerId;
      this.dataFormType = type;
      this.onGetWorkerDetail();
    },
    // 获取人员详情
    onGetWorkerDetail: function () {
      var that = this;
      var data = {
        workerId: this.currentWorkerId,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/projectWorker/detail",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            console.log("获取人员详情", res);
            that.dataForm.workerName = res.data.workerName;
            that.dataForm.gender = res.data.gender;
            that.dataForm.nation = res.data.nation;
            that.dataForm.birthday = that.getFullDateStr(res.data.birthday);
            that.dataForm.address = res.data.address;
            that.dataForm.idcardNumber = res.data.idcardNumber;
            that.dataForm.grantOrg = res.data.grantOrg;
            that.dataForm.startDate = that.getFullDateStr(res.data.startDate);
            that.dataForm.expiryDate = that.getFullDateStr(res.data.expiryDate);
            that.dataForm.avatar = res.data.avatar;
            that.dataForm.frontView = res.data.frontView;
            that.dataForm.reverseView = res.data.reverseView;

            that.dataForm.corpId = res.data.corpName;
            that.dataForm.teamId = res.data.teamName;
            that.dataForm.workerType = res.data.workerType;
            that.dataForm.workCurrentType = res.data.workCurrentType;
            that.dataForm.cellPhone = res.data.cellPhone;
            that.dataForm.entryTime = that.getFullDateStr(res.data.entryTime);

            that.dataForm.cultureLevelType = res.data.cultureLevelType;
            that.dataForm.hasBadMedicalHistory = res.data.hasBadMedicalHistory;
            that.dataForm.politicsType = res.data.politicsType;
            that.dataForm.joinedTime = that.getFullDateStr(res.data.joinedTime);
            that.dataForm.urgentLinkMan = res.data.urgentLinkMan;
            that.dataForm.urgentLinkManPhone = res.data.urgentLinkManPhone;
            that.dataForm.maritalStatus = res.data.maritalStatus;
            that.dataForm.blood = res.data.blood;
            that.dataForm.clockIn = res.data.clockIn;

            that.dataForm.workCorpName = res.data.corpName;

            that.showDataForm = true;
            that.entryExitId = res.data.entryExitId;
          } else {
            that.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 提交
    handleSubmit: function () {
      if (!this.dataForm.workerName) {
        this.$message({
            type: "info",
            message: "请填写姓名或读取二代身份证",
          });
          return
      }
      if (!this.dataForm.gender) {
        this.$message({
            type: "info",
            message: "请填写性别或读取二代身份证",
          });
          return
      }
      if (!this.dataForm.nation) {
        this.$message({
            type: "info",
            message: "请填写民族或读取二代身份证",
          });
          return
      }
      if (!this.dataForm.birthday) {
        this.$message({
            type: "info",
            message: "请填写出生或读取二代身份证",
          });
          return
      }
      if (!this.dataForm.address) {
        this.$message({
            type: "info",
            message: "请填写住址或读取二代身份证",
          });
          return
      }
      if (!this.dataForm.idcardNumber) {
        this.$message({
            type: "info",
            message: "请填写公民身份证号码或读取二代身份证",
          });
          return
      }
      if (!this.dataForm.grantOrg) {
        this.$message({
            type: "info",
            message: "请填写签发机关或读取二代身份证",
          });
          return
      }
      if (!this.dataForm.startDate || !this.dataForm.expiryDate) {
        this.$message({
            type: "info",
            message: "请填写有效日期或读取二代身份证",
          });
          return
      }
      if (!this.dataForm.corpId) {
        this.$message({
            type: "info",
            message: "请选择参建单位",
          });
          return
      }
      if (!this.dataForm.teamId) {
        this.$message({
            type: "info",
            message: "请选择班组",
          });
          return
      }
      if (!this.dataForm.workerType) {
        this.$message({
            type: "info",
            message: "请选择人员类型",
          });
          return
      }
      if (!this.dataForm.workCurrentType) {
        this.$message({
            type: "info",
            message: "请选择工种/岗位",
          });
          return
      }
      if (!this.dataForm.cellPhone) {
        this.$message({
            type: "info",
            message: "请填写正确的手机号",
          });
          return
      }
      if (!this.dataForm.clockIn) {
        this.$message({
            type: "info",
            message: "请拍摄打卡机照片",
          });
          return
      }
      this.$confirm("此操作将提交该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.onSubmit();
        })
        .catch((err) => {
          console.log(err);
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 提交
    onSubmit: function () {
      var that = this;
      var data;
      if (this.dataFormType == "add") {
        data = this.dataForm;
      }
      if (this.dataFormType == "edit") {
        data = {
          workerId: this.currentWorkerId,
          entryExitId: this.entryExitId,
          clockIn: this.dataForm.clockIn,
          cellPhone: this.dataForm.cellPhone,
          address: this.dataForm.address,
          cultureLevelType: this.dataForm.cultureLevelType,
          politicsType: this.dataForm.politicsType,
          isJoined: this.dataForm.isJoined,
          joinedTime: this.dataForm.joinedTime,
          hasBadMedicalHistory: this.dataForm.hasBadMedicalHistory,
          maritalStatus: this.dataForm.maritalStatus,
          specialty: this.dataForm.specialty,
          urgentLinkMan: this.dataForm.urgentLinkMan,
          urgentLinkManPhone: this.dataForm.urgentLinkManPhone,
          blood: this.dataForm.blood,
        };
      }
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/projectWorker/submit",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.handleClose();
            that.onGetListData();
          } else {
            that.$message({
              type: "info",
              message: res.msg,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 多选控制器
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 批量退场
    handleExit: function () {
      this.$confirm("此操作将批量退场, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.onExit();
        })
        .catch((err) => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 批量下发
    handleIssue: function () {
      this.$confirm("此操作将批量下发, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.onIssue();
        })
        .catch((err) => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 执行退场
    onExit: function () {
      var that = this;
      var data = {
        list: this.workIdList,
        projectId: localStorage.getItem('projectId')
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/projectWorker/workerExit",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.onGetListData()
          } else {
            that.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 执行下发
    onIssue: function () {
      var that = this;
      var data = {
        list: this.workIdList,
        projectId: localStorage.getItem('projectId')
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/projectWorker/workerIssue",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.onGetListData()
          } else {
            that.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 多选控制器
    selectable: function (row, index) {
      return row.personnelStatus == '0'
    },
    // 下发结果
    handleLogList: function (row) {
      var that = this;
      var data = {
        workerId: row.workerId,
        projectId: localStorage.getItem('projectId')
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/projectWorker/personnelDeliverResults",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.logList = res.data
            that.showLogList = true
          } else {
            that.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 关闭下发结果
    handleCloseLogList: function () {
      this.logList = []
      this.showLogList = false
    },
    // 获取完整时间格式
    getFullDateStr: function (str) {
      if (!str || str == "" || str == null) {
        return ""
      }
      var date = new Date(str)
      var year = date.getFullYear()
      var month = date.getMonth() + 1
      var day = date.getDate()
      var hour = date.getHours()
      var minute = date.getMinutes()
      var second = date.getSeconds()
      if (month<10) {
        month = '0' + month
      }
      if (day<10) {
        day = '0' + day
      }
      if (hour<10) {
        hour = '0' + hour
      }
      if (minute<10) {
        minute = '0' + minute
      }
      if (month<10) {
        second = '0' + second
      }
      return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second
    },
    //上传头像
    handleUploadAvatar: function (file, fileList) {
      this.base64Data = null
      this.cropperWidth = 102
      this.cropperHeight = 126
      this.cropperType = 'avatar'
      this.changeUpload(file, fileList)
    },
    //上传身份证正面照
    handleUploadFrontView: function (file, fileList) {
      this.base64Data = null
      this.cropperWidth = 327
      this.cropperHeight = 206
      this.cropperType = 'frontView'
      this.changeUpload(file, fileList)
    },
    //上传身份证背面照
    handleUploadReverseView: function (file, fileList) {
      this.base64Data = null
      this.cropperWidth = 327
      this.cropperHeight = 206
      this.cropperType = 'reverseView'
      this.changeUpload(file, fileList)
    },
    //上传打卡机照片
    handleUploadClockIn: function (file, fileList) {
      this.base64Data = null
      this.cropperWidth = 205
      this.cropperHeight = 205
      this.cropperType = 'clockIn'
      this.changeUpload(file, fileList)
    },
    //上传图片文件
    changeUpload: function (file, fileList) {
      var that = this
      var evevt = evevt || window.event
      var file = evevt.target.files[0]
      var reader = new FileReader()
      reader.onload = function (e) {
        that.base64Data = e.target.result
        that.option.img = e.target.result
        that.innerVisible = true
      }
      reader.readAsDataURL(file)
    },
    // 实时预览
    realTime: function (data) {
      var previews = data
      var h = 0.5
      var w = 0.2
      // 固定为 100 宽度
      this.previewStyle = {
        width: previews.w + "px",
        height: previews.h + "px",
        overflow: "hidden",
        margin: "0",
        zoom: 200 / previews.w
      }
      this.previews = data
    },
    // 获取截图
    getCropData: function () {
      this.$refs.cropper.getCropData(data => {
        this.dataForm[this.cropperType] = data
        this.innerVisible = false
        // 初始化所有变量
        this.base64Data = null
        this.cropperWidth = 0
        this.cropperHeight = 0
        this.cropperType = ''
        this.option.img = ''
      })
    },
    // 放大缩小
    changeScale: function (num) {
      num = num || 1
      this.$refs.cropper.changeScale(num)
    },
    // 旋转
    rotateLeft: function () {
      this.$refs.cropper.rotateLeft()
    },
    rotateRight: function () {
      this.$refs.cropper.rotateRight()
    },
    // 批量导出
    handleRealNameExport: function () {
      var that = this;
      var data = this.workIdList;
      var timestamp = new Date().getTime()
      that
        .$axiosAdmin({
          method: "post",
          responseType: "blob",
          url: "api/qingxin-platform-workers/ExcelImport/ryByIdExcelExport",
          data: JSON.stringify(data),
        })
        .then((res) => {
          const blob = res;
          that.$refs.download.href = window.URL.createObjectURL(new Blob([blob], {type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
          that.$refs.download.download = `批量导出 ${timestamp}.xlsx`;
          that.$refs.download.click();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 导出当前数据 handleRealNameExportResult
    handleRealNameExportResult: function () {
      var that = this;
      var timestamp = new Date().getTime()
      var data = {
        projectId: localStorage.getItem("projectId"),
        corpId: this.corpId,
        teamId: this.teamId,
        // status: 0,
        // pageSize: this.pageSize,
        // pageNum: this.pageNum,
        workerName: this.workerName,
        isComplete: this.isComplete,
        personnelStatus: this.personnelStatus,
      };
      that
        .$axiosAdmin({
          method: "post",
          responseType: "blob",
          url: "api/qingxin-platform-workers/ExcelImport/ryExcelExport",
          data: JSON.stringify(data),
        })
        .then((res) => {
          const blob = res;
          that.$refs.download.href = window.URL.createObjectURL(new Blob([blob], {type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
          that.$refs.download.download = `导出列表数据 ${timestamp}.xlsx`;
          that.$refs.download.click();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 人员导入
    handleRealNameImport: function (file) {
      var that = this;
      var data = new FormData()
      data.append('projectId', localStorage.getItem('projectId'))
      data.append('file', file.raw)
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/ExcelImport/ryExcelImport",
          data: data,
        })
        .then((res) => {
          if (res.success) {
            var msg = res.data.join('')
            that.$confirm(msg, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
              showCancelButton: false,
            })
              .then(() => {
                that.pageNum = 1
                that.onGetListData()
              })
          } else {
            that.$confirm(res.msg, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
              showCancelButton: false,
            })
          }
        })
        .catch((err) => {
          console.log('handleRealNameImport  Err=>', err);
        });

    },
    // 打卡机照片导入
    handleRealNameImportPic: function (file) {
      var fileNameArr = file.name.split('.')
      if (fileNameArr.length < 2) {
        this.$confirm('未知的文件类型，请重新选择文件。', "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          showCancelButton: false,
        })
        return
      }
      if (fileNameArr[1] != 'zip') {
        this.$confirm('仅支持 zip 包导入，请重新选择文件。', "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          showCancelButton: false,
        })
        return
      }
      var that = this;
      var data = new FormData()
      data.append('wayStatus', 0)
      data.append('file', file.raw)
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/ExcelImport/zipImport",
          data: data,
        })
        .then((res) => {
          if (res.success) {
            var msg = res.data.join('')
            that.$confirm(msg, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
                showCancelButton: false,
              })
                .then(() => {
                  that.pageNum = 1
                  that.onGetListData()
                })
          } else {
            that.$confirm(res.msg, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
              showCancelButton: false,
            })
          }
        })
        .catch((err) => {
          console.log('handleRealNameImport  Err=>', err);
        });
    },
    // 人员头像导入
    handleRealNameImportIcon: function (file) {
      var fileNameArr = file.name.split('.')
      if (fileNameArr.length < 2) {
        this.$confirm('未知的文件类型，请重新选择文件。', "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          showCancelButton: false,
        })
        return
      }
      if (fileNameArr[1] != 'zip') {
        this.$confirm('仅支持 zip 包导入，请重新选择文件。', "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          showCancelButton: false,
        })
        return
      }
      var that = this;
      var data = new FormData()
      data.append('wayStatus', 1)
      data.append('file', file.raw)
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/ExcelImport/zipImport",
          data: data,
        })
        .then((res) => {
          if (res.success) {
            var msg = res.data.join('')
            that.$confirm(msg, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
                showCancelButton: false,
              })
                .then(() => {
                  that.pageNum = 1
                  that.onGetListData()
                })
          } else {
            that.$confirm(res.msg, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
              showCancelButton: false,
            })
          }
        })
        .catch((err) => {
          console.log('handleRealNameImport  Err=>', err.response);
          that.$confirm(err.response.data.msg, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
              showCancelButton: false,
            })
        });
    },
    // 下载模板
    handleRealNameTemplate: function () {
      this.$refs.download.href = 'https://qxrealname.hbzhijian.cn/api/qingxin-resource/sys-file/qxkj-document/realname.zip'
      this.$refs.download.download = `实名制数据导入模板及说明.zip`;
      this.$refs.download.click();
    }
  },
};
</script>
    